import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';
import config from '@/config';
import store from "../store";


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });


instance.interceptors.response.use((response) => {
  return response
}, (error) => {

  if (error.response.status === 401) {
    return store.dispatch('auth/refresh').then(
      () => {
        error.config.headers = authHeader();
        error.config.baseURL = undefined;
        return instance.request(error.config);
      },
      error => {
        AuthService.logout()
      }
    );
  } else {
    return Promise.reject(error)
  }
});


class FinanceGroupService {
  save(store) {
    return instance.post('markets/finance', store, { headers: authHeader() });
  }

  getBoard() {
    return instance.get('markets/finance/list', { headers: authHeader() });
  }

  delete(id) {
    return instance.delete('markets/finance/' + id, { headers: authHeader() });
  }

  get(id) {
    return instance.get('markets/finance/' + id, { headers: authHeader() });
  }
}

export default new FinanceGroupService();
