export default class IntegrationProduct {
    constructor(id, sku, base_sku, base_price, price, commission, integration_id) {
      this.sku = sku;
      this.id = id;
      this.base_sku = base_sku;
      this.price = price;
      this.base_price = base_price;
      this.commission = commission
      this.integration_id = integration_id
    }
  }