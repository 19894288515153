<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка финансовой группы</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name">
        </base-input>
      </div>
      <div class="col-md-5 pr-md-1">
        <base-input type="number" label="Остаток денег на руках" placeholder="Остаток денег на руках" v-model.number="model.money">
        </base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
  </card>
</template>
<script>
import FinanceGroup from '../models/finance_group';
import FinanceGroupService from '../services/finance_group.service';

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: new FinanceGroup(null, '', 0),
    };
  },
  mounted() {
    if (this.id !== '') {
      this.disabled = 1;
      FinanceGroupService.get(this.id).then(
        response => {
          this.model.id = response.data['id']
          this.model.name = response.data['name']
          this.model.money = response.data['money']
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    save() {
      FinanceGroupService.save(this.model).then(
        response => {
          this.$router.push('/finance-groups');
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    },
  }
};
</script>
<style>
.form-check .form-check-label {
      margin-bottom: 5px;
      display: block;
}
.form-check {
    padding-left: 1.25rem;
}
.check-group {
  display: flex;
}
.secondary-card{
  box-shadow: none;
}
</style>
