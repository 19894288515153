<template>
  <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createFinanceGroup">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editFinanceGroup"
            :deleteAction="deleteFinanceGroup" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import FinanceGroupService from '../services/finance_group.service';
import { BaseTable } from "@/components";

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table: {
        title: "Финансовые группы",
        columns: ["name", "money"],
        actions: ["edit", "delete"],
        data: []
      },
    };
  },
  mounted() {
    FinanceGroupService.getBoard().then(
      response => {
        this.table.data = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    createFinanceGroup() {
      this.$router.push('/finance-group-create');
    },
    deleteFinanceGroup: function (id) {
      FinanceGroupService.delete(id).then(
        response => {
          FinanceGroupService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    editFinanceGroup: function (id) {
      this.$router.push('/finance-group-edit/' + id);
    }
  }
};
</script>
<style></style>
