export default class StoreIntegration {
    constructor(id, name, token, store_id, agent, storage, additional_id, is_dbs, megamarket_auto_approve, market_type, 
      payment_frequency, ya_business_id, warehouse_id, additional_expenses, use_base_price, active) {
      this.id = id;
      this.name = name;
      this.token = token;
      this.store_id = store_id;
      this.agent = agent;
      this.storage = storage;
      this.additional_id = additional_id;
      this.is_dbs = is_dbs;
      this.megamarket_auto_approve = megamarket_auto_approve;
      this.market_type = market_type;
      this.active = active
      this.payment_frequency = payment_frequency
      this.ya_business_id = ya_business_id
      this.warehouse_id = warehouse_id
      this.additional_expenses = additional_expenses
      this.use_base_price = use_base_price
    }
  }