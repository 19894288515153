import axios from 'axios';
import AuthService from './auth.service';
import authHeader from './auth-header';
import config from '@/config';
import store from "../store";


const instance = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });


instance.interceptors.response.use((response) => {
  return response
}, (error) => {

  if (error.response.status === 401) {
    return store.dispatch('auth/refresh').then(
      () => {
        error.config.headers = authHeader();
        error.config.baseURL = undefined;
        return instance.request(error.config);
      },
      error => {
        AuthService.logout()
      }
    );
  } else {
    return Promise.reject(error)
  }
});


class ProductService {
  save(integration_id, base_product) {
    return instance.post(`markets/integration/${integration_id}/product`, base_product, { headers: authHeader() });
  }

  save_store_product(id, store_product) {
    return instance.post(`markets/store/${id}/store-product`, store_product, { headers: authHeader() })
  }

  getBoard(store_id, paging) {
    return instance.post(`markets/store/${store_id}/products`, paging, { headers: authHeader() });
  }

  product_commission(integration_id, commission_request) {
    return instance.post(`markets/integration/${integration_id}/commission`, commission_request, { headers: authHeader() });
  }

  get(store_id, sku) {
    return instance.get('markets/store/' + store_id + '/store-product/' + sku, { headers: authHeader() });
  }
}

export default new ProductService();
