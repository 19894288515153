export default class StoreProfitSettings {
    constructor(up_to_ten, up_to_twenty, up_to_thirty, up_to_forty, up_to_fifty, up_to_sixty, up_to_seventy, up_to_eighty, up_to_ninety, up_to_one, up_to_one_ten, up_to_one_twenty, up_to_one_thirty, up_to_one_forty, up_to_one_fifty, other) {
      this.up_to_ten = up_to_ten;
      this.up_to_twenty = up_to_twenty;
      this.up_to_thirty = up_to_thirty;
      this.up_to_forty = up_to_forty;
      this.up_to_fifty = up_to_fifty;
      this.up_to_sixty = up_to_sixty;
      this.up_to_seventy = up_to_seventy;
      this.up_to_eighty = up_to_eighty;
      this.up_to_ninety = up_to_ninety;
      this.up_to_one = up_to_one;
      this.up_to_one_ten = up_to_one_ten;
      this.up_to_one_twenty = up_to_one_twenty;
      this.up_to_one_thirty = up_to_one_thirty;
      this.up_to_one_forty = up_to_one_forty;
      this.up_to_one_fifty = up_to_one_fifty;
      this.other = other;
    }
  }