<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <h5 slot="header" class="title">Карточка интеграции</h5>
    <div class="row">
      <div class="col-md-5 pr-md-1">
        <base-input label="Название" placeholder="Название" v-model="model.name" :disabled="disabled == 1">
        </base-input>
      </div>
      <div class="col-md-3">
        <label class="control-label">Магазин</label>
        <v-select :searchable="false" :clearable="false"  v-on:option:selected="handleBlur" v-model="model.store_id" :reduce="store => store.id" label="name" :options="stores" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-2">
        <label class="control-label">Маркетплейс</label>
        <v-select :searchable="false" :clearable="false" v-model="model.market_type" :reduce="market => market.id" :options="markets" :disabled="disabled == 1"></v-select>
      </div>
      <div class="col-md-5 pr-md-1">
          <base-input label="Токен" placeholder="Токен" v-model="model.token">
          </base-input>
        </div>
      <div class="col-md-1"  v-if="model.market_type === 2">
        <div class="form-group">
          <label class="control-label">ДБС</label>
          <base-checkbox v-model="model.is_dbs">
          </base-checkbox>
        </div>
      </div>
      <div class="col-md-1"  v-if="model.market_type === 2">
        <div class="form-group">
          <label class="control-label">Использовать общую цену</label>
          <base-checkbox v-model="model.use_base_price">
          </base-checkbox>
        </div>
      </div>
      <div class="col-md-2" v-if="model.market_type === 2">
        <base-input label="campaign_id магазина в ЯМ" placeholder="campaign_id" v-model="model.additional_id">
        </base-input>
      </div>
      <div class="col-md-2" v-if="model.market_type === 3">
        <base-input label="Client-Id в ozon" placeholder="Client-Id" v-model="model.additional_id">
        </base-input>
      </div>
      <div class="col-md-2" v-if="model.market_type === 2">
        <base-input label="business_id магазина в ЯМ" placeholder="business_id" v-model="model.ya_business_id">
        </base-input>
      </div>
      <div class="col-md-2" v-if="model.market_type === 2 || model.market_type === 3">
        <base-input label="warehouse_id склада в маркетплейсе" placeholder="warehouse_id" v-model="model.warehouse_id">
        </base-input>
      </div>
      <div class="col-md-4" v-if="model.market_type === 1">
        <div class="form-group">
          <label class="control-label">Автоподтверждение в ММ</label>
          <base-checkbox v-model="model.megamarket_auto_approve">
          </base-checkbox>
        </div>
      </div>
      <div class="col-md-2" v-if="model.market_type === 2">
        <label class="control-label">Частота выплат</label>
        <v-select :searchable="false" :clearable="false" v-model="model.payment_frequency" :reduce="payment => payment.id" :options="payment_frequencyes"></v-select>
      </div>
      <div class="col-md-2">
        <base-input label="Доп процент затрат" placeholder="%" v-model="model.additional_expenses">
        </base-input>
      </div>
    </div>
    <card class="secondary-card mt-3" v-if="model.id === null">
      <h5 slot="header" class="title">Настройки МойСклад</h5>
      <div class="row">
        <div class="col-md-3">
          <label class="control-label">Контрагент в МойСклад</label>
          <v-select :clearable="false" v-model="model.agent" :options="agents" :disabled="disabled == 1"></v-select>
        </div>
        <div class="col-md-3">
          <label class="control-label">Склад в МойСклад</label>
          <v-select :clearable="false" v-model="model.storage" :options="storages" :disabled="disabled == 1"></v-select>
        </div>
      </div>
    </card>
    <base-button slot="footer" type="primary" fill @click="save">Сохранить</base-button>
  </card>
</template>
<script>
import MoyskladService from '../services/moysklad.service';
import StoreIntegrationService from '../services/store_integration.service';
import StoreIntegration from '../models/store_integration';
import StoreService from '../services/store.service';
import Modal from '@/components/Modal';
import { BaseTable } from "@/components";

export default {
  components: {
    Modal,
    BaseTable
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: new StoreIntegration(null, '', '', null, '', '', '', false, false, null, null, '', '', 0, false, true),
      markets: [{'id': 1, 'label': 'Мегамаркет'}, {'id': 2, 'label': 'Яндекс Маркет'}, {'id': 3, 'label': 'Ozon'}],
      payment_frequencyes: [{'id': 1, 'label': 'Ежедневно'}, {'id': 2, 'label': 'Еженедельно'}, {'id': 3, 'label': 'Два раза в месяц'}, {'id': 4, 'label': 'Ежемесячно'}],
      stores: [],
      disabled: 0,
      org_list: [],
      agents: [],
      storages: [],
      status_list: [],
    };
  },
  mounted() {
    StoreService.getBoard().then(
      response => {
        this.stores = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    if (this.id !== '') {
      this.disabled = 1;
      StoreIntegrationService.get(this.id).then(
        response => {
          this.model = new StoreIntegration(this.id, response.data['name'], response.data['token'], response.data['store_id'], '', '', response.data['additional_id'], response.data['is_dbs'], response.data['megamarket_auto_approve'], response.data['market'], response.data['payment_frequency'], response.data['ya_business_id'], response.data['warehouse_id'], response.data['additional_expenses'], response.data['use_base_price'], response.data['active'])
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  methods: {
    save() {
      StoreIntegrationService.save(this.model).then(
        response => {
          this.$router.push('/store-integrations');
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    },
    handleBlur() {
        MoyskladService.agents(null, null, this.model.store_id).then(
        response => {
          this.agents = response.data
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
        MoyskladService.storages(null, null, this.model.store_id).then(
        response => {
          this.storages = response.data
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        });
    }
  }
};
</script>
<style>
.form-check .form-check-label {
      margin-bottom: 5px;
      display: block;
}
.form-check {
    padding-left: 1.25rem;
}
.check-group {
  display: flex;
}
.secondary-card{
  box-shadow: none;
}
</style>
