<template>
  <div class="row">
    <div class="col-12">
      <card :title="table.title">
        <base-button slot="footer" type="primary" fill @click="createStoreIntegration">Создать</base-button>
        <div class="table-responsive">
          <base-table :data="table.data" :columns="table.columns" :actions="table.actions" :editAction="editStoreIntegration"
            :deleteAction="deleteStoreIntegration" thead-classes="text-primary">
          </base-table>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import StoreIntegrationService from '../services/store_integration.service';
import { BaseTable } from "@/components";

export default {
  components: {
    BaseTable
  },
  data() {
    return {
      table: {
        title: "Интеграции",
        columns: ["name"],
        actions: ["edit", "delete"],
        data: []
      },
    };
  },
  mounted() {
    StoreIntegrationService.getBoard().then(
      response => {
        this.table.data = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    createStoreIntegration() {
      this.$router.push('/store-integration-create');
    },
    deleteStoreIntegration: function (id) {
      StoreIntegrationService.delete(id).then(
        response => {
          StoreIntegrationService.getBoard().then(
            response => {
              this.table.data = response.data;
            },
            error => {
              this.content =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    editStoreIntegration: function (id) {
      this.$router.push('/store-integration-edit/' + id);
    }
  }
};
</script>
<style></style>
