export default class Store {
    constructor(id, name, additional_expenses, create_products, finance_group, moysklad_organization, store_profit_settings) {
      this.id = id;
      this.name = name;
      this.additional_expenses = additional_expenses;
      this.create_products = create_products;
      this.finance_group = finance_group;
      this.moysklad_organization = moysklad_organization;
      this.store_profit_settings = store_profit_settings;
    }
  }